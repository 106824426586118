import React from 'react'
import './footer.css'
import { FaFacebookF } from 'react-icons/fa'
import { FiInstagram } from 'react-icons/fi'
import { IoLogoTwitter } from 'react-icons/io'



function Footer() {
  return (
    <footer>
      <ul className='permalinks'>
        <li><a href="#">Home</a></li>
        <li><a href="#about">About</a></li>
        <li><a href="#skills">Skills</a></li>
        <li><a href="#projects">Portfolio</a></li>
        <li><a href="#contact">Contact</a></li>
      </ul>

      <div className="footer__socials">
        <a href="https://www.facebook.com/srikantchilga"><FaFacebookF/></a>
        <a href="https://www.instagram.com/srikant__loric/"><FiInstagram/></a>
        <a href="https://mobile.twitter.com/srikantloric"><IoLogoTwitter/></a>
      </div>


      <div className="footer__copyright">
        <small>&copy; srikantloric.in All rights reserved</small>
      </div>
    </footer>
  )
}

export default Footer
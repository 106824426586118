import React, { useState,useEffect } from 'react'
import './nav.css'
import {AiOutlineHome,AiOutlineUser} from 'react-icons/ai'
import {BiBook} from 'react-icons/bi'
import {RiServiceLine} from 'react-icons/ri'
import {BiMessageSquareDetail} from 'react-icons/bi'
import {VscFolderLibrary} from 'react-icons/vsc'



function Nav() {

  const [activeNav,setActiveNav]  = useState("#")

  const [scrollPosition, setScrollPosition] = useState(0);
  const handleScroll = () => {
    const position = window.pageYOffset;

    if (position < 500) {
      setActiveNav("#")
    }
    if (position > 500) {
      setActiveNav("#about")
    }
    if (position > 1200) {
      setActiveNav("#skills")
    }
    if (position > 1900) {
      setActiveNav("#projects")
    }
    if (position > 3000) {
      setActiveNav("#contact")
    }
    
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <nav>
   
  
      <a href="#" onClick={()=>setActiveNav("#")} className={activeNav==="#"?'active':''}><AiOutlineHome/></a>
      <a href="#about" onClick={()=>setActiveNav('#about')} className={activeNav==='#about'?'active':''}><AiOutlineUser/></a>
      <a href="#skills" onClick={()=>setActiveNav('#skills')} className={activeNav==='#skills'?'active':''}><BiBook/></a>
      <a href="#projects" onClick={()=>setActiveNav('#projects')} className={activeNav==='#projects'?'active':''}><VscFolderLibrary/></a>
      <a href="#contact" onClick={()=>setActiveNav('#contact')} className={activeNav==='#contact'?'active':''}><BiMessageSquareDetail/></a>
    </nav>
  )
}

export default Nav
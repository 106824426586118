import React from 'react'
import {BsLinkedin} from 'react-icons/bs'
import {FaGithub} from 'react-icons/fa'
import {FiDribbble} from 'react-icons/fi'

function HeaderSocials() {
  return (
      <div className="header__socials">
          <a href="https://in.linkedin.com/in/srikant-loric-87a446199" target="_blank"><BsLinkedin/></a>
          <a href="https://github.com/srikantloric" target="_blank"><FaGithub/></a>
          <a href="https://dribbble.com/" target="_blank"><FiDribbble/></a>
    </div>
  )
}

export default HeaderSocials
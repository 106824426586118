import React from 'react'
import './portfolio.css'

import IMG1 from '../../assets/portfolio1.jpg'
import IMG2 from '../../assets/portfolio2.jpg'
import IMG3 from '../../assets/portfolio3.jpg'
import IMG4 from '../../assets/portfolio4.jpg'
import IMG5 from '../../assets/portfolio5.jpg'


function Portfolio() {

  const porfolioData = [
    {
      id: 1,
      title:"Classroom Jugaad (Android App)",
      url: "https://play.google.com/store/apps/details?id=com.loricsoftwares.classroomjugaad",
      image_url:IMG1
      
    },
    {
      id: 2,
      title:"Aacharyan (Android App)",
      url: "https://play.google.com/store/apps/details?id=com.loric.softwares.aacharya",
      image_url:IMG3
    },
    {
      id: 3,
      title:"Saviour (Android App)",
      url: "https://play.google.com/store/apps/details?id=com.srikantloricc.saviour",
      image_url:IMG5
      
    },
    {
      id: 4,
      title:"KCS (Android App)",
      url: "",
      image_url:IMG2
      
    },
    {
      id: 5,
      title:"Aacharyan Manager (React App)",
      url: "https://aacharya-12699.firebaseapp.com/",
      image_url:IMG4
      
    },
  ]








  return (
    <section id='projects'>
      <h5>My Recent Works</h5>
      <h2>Portfolio</h2>

      <div className="container portfolio__container">


        {porfolioData.map((item) => (
            <article className='portfolio__item'>
            <div className="portfolio__item-image">
              <img src={item.image_url} alt="" />
              </div>
            <h3>{item.title}</h3>
            <div className='portfolio__item-cta'>
            <a href="" className='btn' target='_blank'>Github</a>
              <a href={item.url} target='_blank' className='btn btn-primary'>Live Demo</a>
            </div>
          </article>
        ))}
      </div>

    </section>
  )
}

export default Portfolio